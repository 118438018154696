var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "perinta-nayta" },
    [
      _c(
        "ViewTopBarBase",
        { attrs: { title: _vm.routeTitle } },
        [
          _vm.oikeusAinakinYhteenMenunToiminnoista
            ? _c("v-menu", {
                attrs: { bottom: "", left: "", "nudge-bottom": "40" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              { attrs: { color: "success", depressed: "" } },
                              on
                            ),
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:actions")) + " "
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v(" more_vert "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c(
                            "v-list",
                            [
                              _vm.$permission.checkSinglePerm(
                                "onlinerestapi.onlinerestapi_toimeksianto_ohisuoritus"
                              )
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        disabled: !_vm.ohisuoritusEnabled,
                                      },
                                      on: { click: _vm.avaaDialogOhisuoritus },
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [_c("v-icon", [_vm._v("redo")])],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "common:collectionShow.informBypass"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$permission.checkSinglePerm(
                                "onlinerestapi.onlinerestapi_viesti_lahetys"
                              )
                                ? _c(
                                    "v-list-item",
                                    { on: { click: _vm.avaaDialogViestit } },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [_c("v-icon", [_vm._v("comment")])],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("common:sendMessage")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { on: { click: _vm.avaaDialogAsiakirja } },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [_c("v-icon", [_vm._v("attachment")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common:addAttachment")) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  212093277
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.tietueLataamassa
        ? _c("Spinner")
        : _vm.tietueLatausEpaonnistunut
        ? _c("CardPahoittelu")
        : [
            _c("v-container", { staticClass: "pa-2", attrs: { fluid: "" } }, [
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c("TietueenPerustiedotCard", {
                    attrs: { paamies: _vm.perintaNayta.toimeksianto.paamies },
                    scopedSlots: _vm._u([
                      {
                        key: "vastaanottaja",
                        fn: function () {
                          return _vm._l(
                            _vm.perintaNayta.toimeksianto
                              .toimeksiantovelallinen_set,
                            function (taVe) {
                              return _c(
                                "div",
                                { key: taVe.id, staticClass: "mb-4" },
                                [
                                  _vm.eiOikeuttaNahdaAsiakastaValueVelallinen(
                                    _vm.perintaNayta.toimeksianto,
                                    taVe
                                  )
                                    ? [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mb-0 font-weight-bold grey--text darken-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common:debtor")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "font-italic" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common:notAbleToShowRestriction"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : taVe.osoite.lahde === "trafi" &&
                                      _vm.trafiLupaNaytaVainNimi &&
                                      !_vm.trafiLupaNaytaKaikki
                                    ? [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mb-0 font-weight-bold grey--text darken-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(taVe.osallisuusasema) +
                                                " (" +
                                                _vm._s(taVe.vastuuprosentti) +
                                                " %) "
                                            ),
                                          ]
                                        ),
                                        _c("h3", [
                                          _vm._v(
                                            _vm._s(taVe.velallinen.nimi) +
                                              " (" +
                                              _vm._s(taVe.velallinen.id) +
                                              ")"
                                          ),
                                        ]),
                                      ]
                                    : [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "mb-0 font-weight-bold grey--text darken-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(taVe.osallisuusasema) +
                                                " (" +
                                                _vm._s(taVe.vastuuprosentti) +
                                                " %) "
                                            ),
                                          ]
                                        ),
                                        _c("h3", [
                                          _vm._v(
                                            _vm._s(taVe.velallinen.nimi) +
                                              " (" +
                                              _vm._s(taVe.velallinen.id) +
                                              ")"
                                          ),
                                        ]),
                                        taVe.velallinen.syntymapaiva
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("common:born")) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm.$date(
                                                      taVe.velallinen
                                                        .syntymapaiva
                                                    )
                                                  )
                                              ),
                                            ])
                                          : taVe.velallinen.tyyppi == "Y" &&
                                            taVe.velallinen.tunnus
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(taVe.velallinen.tunnus)
                                              ),
                                            ])
                                          : _vm._e(),
                                        taVe.velallinen.postiosoite
                                          ? _c("OsoiteKappale", {
                                              attrs: {
                                                postiosoite:
                                                  taVe.velallinen.postiosoite
                                                    .osoite,
                                                postinumero:
                                                  taVe.velallinen.postiosoite
                                                    .numero,
                                                postitoimipaikka:
                                                  taVe.velallinen.postiosoite
                                                    .toimipaikka,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                ],
                                2
                              )
                            }
                          )
                        },
                        proxy: true,
                      },
                      {
                        key: "col2",
                        fn: function () {
                          return [
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListItem", {
                                  attrs: {
                                    "label-class": "defList__label--emphasized",
                                    "value-class": "font-weight-bold",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common:caseNo")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.perintaNayta.toimeksianto.id
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.perintaNayta
                                                        .toimeksianto.id
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common:status")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.perintaNayta.toimeksianto.tila &&
                                      _vm.perintaNayta.toimeksianto
                                        .ikoni_ja_vari
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _c("StatusText", {
                                                  attrs: {
                                                    text: _vm.perintaNayta
                                                      .toimeksianto.tila.selite,
                                                    color:
                                                      _vm.perintaNayta
                                                        .toimeksianto
                                                        .ikoni_ja_vari.vari,
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common:transactionDate"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.perintaNayta.toimeksianto.luotu
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$date(
                                                        _vm.perintaNayta
                                                          .toimeksianto.luotu
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm.perintaNayta.toimeksianto.sopimus
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:contractNumber"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.perintaNayta
                                                        .toimeksianto.sopimus
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        4040240916
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "col3",
                        fn: function () {
                          return [
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("common:phase")) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.perintaNayta.toimeksianto.vaihe
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.perintaNayta
                                                        .toimeksianto.vaihe.nimi
                                                    ) +
                                                    " "
                                                ),
                                                _vm.perintaNayta.toimeksianto
                                                  .vaihepaiva
                                                  ? [
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(
                                                            _vm.$date(
                                                              _vm.perintaNayta
                                                                .toimeksianto
                                                                .vaihepaiva
                                                            )
                                                          ) +
                                                          ") "
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common:dueDate")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.perintaNayta.toimeksianto
                                        .saatavarivi_set.length
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$date(
                                                        _vm.perintaNayta
                                                          .toimeksianto
                                                          .saatavarivi_set[0]
                                                          .erapaiva
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common:nextPhase")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.perintaNayta.toimeksianto.seuraava
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.perintaNayta
                                                        .toimeksianto.seuraava
                                                        .nimi
                                                    ) +
                                                    " "
                                                ),
                                                _vm.perintaNayta.toimeksianto
                                                  .seuraavapaiva
                                                  ? [
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(
                                                            _vm.$date(
                                                              _vm.perintaNayta
                                                                .toimeksianto
                                                                .seuraavapaiva
                                                            )
                                                          ) +
                                                          ") "
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "col4",
                        fn: function () {
                          return [
                            _c(
                              "DefList",
                              { attrs: { bordered: true } },
                              [
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common:accountConnection"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.perintaNayta.toimeksianto.paamies
                                        .maksutieto
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.perintaNayta
                                                        .toimeksianto.paamies
                                                        .maksutieto.iban
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "common:paymentReference"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      _vm.perintaNayta.toimeksianto.viite
                                        ? {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.perintaNayta
                                                        .toimeksianto.viite
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("DefListItem", {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("common:open")) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "value",
                                      fn: function () {
                                        return [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatSumToFixed2")(
                                                  _vm.perintaNayta.toimeksianto
                                                    .avoinna
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm.perintaNayta.toimeksianto
                                            .nayta_kuluja_auki_teksti
                                            ? [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.$t("common:feesOpen")
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _vm.perintaNayta.toimeksianto.pm_asiakasnumero
                                  ? _c("DefListItem", {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common:pmCustomerNo"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "value",
                                            fn: function () {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.perintaNayta
                                                        .toimeksianto
                                                        .pm_asiakasnumero
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2684932828
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", lg: "9" } }, [
                        _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pa-6" },
                              [
                                _c("CardTitle", {
                                  attrs: {
                                    text: _vm.$t(
                                      "common:collectionShow.receivablesBreakdown"
                                    ),
                                  },
                                }),
                                _c("v-data-table", {
                                  attrs: {
                                    items: _vm.saatavaerittelyItems,
                                    headers:
                                      _vm.tableHeadersSaatavaerittelyFiltered,
                                    "hide-default-footer":
                                      _vm.saatavaerittelyItems.length <= 10,
                                    "no-data-text": _vm.$t(
                                      "common:collectionShow.noReceivables"
                                    ),
                                    dense: _vm.user.tiivisNakyma,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c("TableRow", [
                                              _c(
                                                "td",
                                                { staticClass: "text-no-wrap" },
                                                [
                                                  item.laskunumero
                                                    ? _c(
                                                        "RouterLinkEhdollinen",
                                                        {
                                                          attrs: {
                                                            ehto: Boolean(
                                                              item.lasku_id
                                                            ),
                                                            to: {
                                                              name: "laskutus-nayta",
                                                              params: {
                                                                id: item.lasku_id,
                                                              },
                                                            },
                                                            "custom-contextmenu":
                                                              "",
                                                          },
                                                          on: {
                                                            contextmenu:
                                                              function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.avaaMenuContext(
                                                                  $event,
                                                                  {
                                                                    id: item.lasku_id,
                                                                  },
                                                                  "laskutus-nayta"
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.laskunumero
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-no-wrap" },
                                                [
                                                  item.laskupaiva
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$date(
                                                                item.laskupaiva
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-no-wrap" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$date(item.erapaiva)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-no-wrap" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.pm_viite) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "max-width" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.selite) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSumToFixed2"
                                                        )(item.paaoma)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm.showSaatavaerittelyKulu
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      item.kulu
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(item.kulu)
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _vm.showSaatavaerittelyViivkorko
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      item.viivkorko
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(
                                                                    item.viivkorko
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-no-wrap text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatSumToFixed2"
                                                        )(item.avoinna)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      _vm.perintaNayta.toimeksianto
                                        .saatavarivi_set.length
                                        ? {
                                            key: "body.append",
                                            fn: function () {
                                              return [
                                                _c("TableRow", [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                      attrs: { colspan: "5" },
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "common:total"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-right",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              _vm.perintaNayta
                                                                .toimeksianto
                                                                .paaoma
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm.showSaatavaerittelyKulu
                                                    ? _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-no-wrap text-right",
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatSumToFixed2"
                                                                )(
                                                                  _vm
                                                                    .perintaNayta
                                                                    .toimeksianto
                                                                    .kulu
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.showSaatavaerittelyViivkorko
                                                    ? _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-no-wrap text-right",
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatSumToFixed2"
                                                                )(
                                                                  _vm
                                                                    .perintaNayta
                                                                    .toimeksianto
                                                                    .viivkorko
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap text-right",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              _vm.perintaNayta
                                                                .toimeksianto
                                                                .avoinna
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.perintaNayta.toimeksianto.maksusopimus
                          ? _c(
                              "div",
                              { staticClass: "py-4" },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-6" },
                                  [
                                    _c("CardTitle", {
                                      attrs: {
                                        text: _vm.$t("common:paymentContract"),
                                      },
                                    }),
                                    typeof _vm.perintaNayta.toimeksianto
                                      .maksusopimus === "string"
                                      ? _c("p", { staticClass: "px-4 py-2" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.perintaNayta.toimeksianto
                                                  .maksusopimus
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : [
                                          _c(
                                            "p",
                                            { staticClass: "px-4 text-right" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("common:total")
                                                  ) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm.$date(
                                                      _vm.perintaNayta
                                                        .toimeksianto
                                                        .maksusopimus.luotu
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("v-data-table", {
                                            attrs: {
                                              items: _vm.maksusopimusItems,
                                              headers:
                                                _vm.tableHeadersMaksusopimus,
                                              "hide-default-footer":
                                                _vm.maksusopimusItems.length <=
                                                10,
                                              "no-data-text": _vm.$t(
                                                "common:collectionShow.noReceivables"
                                              ),
                                              dense: _vm.user.tiivisNakyma,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "TableRow",
                                                        {
                                                          attrs: {
                                                            yellow:
                                                              _vm.perintaNayta
                                                                .toimeksianto
                                                                .tila.selite !==
                                                                _vm.$t(
                                                                  "common:paid"
                                                                ) &&
                                                              item.erapaiva_mennyt &&
                                                              item.avoinna > 0,
                                                            green:
                                                              item.avoinna ===
                                                              0,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "max-width text-no-wrap",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.era
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-no-wrap text-right",
                                                            },
                                                            [
                                                              item.erapaiva
                                                                ? [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$date(
                                                                            item.erapaiva
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-no-wrap text-right",
                                                            },
                                                            [
                                                              item.maksupaiva
                                                                ? [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$date(
                                                                            item.maksupaiva
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-no-wrap text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formatSumToFixed2"
                                                                    )(
                                                                      item.summa
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-no-wrap text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formatSumToFixed2"
                                                                    )(
                                                                      item.avoinna
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "body.append",
                                                  fn: function () {
                                                    return [
                                                      _c("TableRow", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                            attrs: {
                                                              colspan: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common:total"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-right",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(
                                                                    _vm
                                                                      .perintaNayta
                                                                      .toimeksianto
                                                                      .maksusopimus
                                                                      .summa
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(
                                                                    _vm
                                                                      .perintaNayta
                                                                      .toimeksianto
                                                                      .maksusopimus
                                                                      .avoinna
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2281224316
                                            ),
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "py-4" },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pa-6" },
                              [
                                _c("CardTitle", {
                                  attrs: {
                                    text: _vm.$t("common:transactions"),
                                  },
                                }),
                                _c("v-data-table", {
                                  attrs: {
                                    items: _vm.kirjauksetItems,
                                    headers: _vm.tableHeadersKirjauksetFiltered,
                                    "hide-default-footer":
                                      _vm.kirjauksetItems.length <= 10,
                                    "no-data-text": _vm.$t(
                                      "common:noTransactions"
                                    ),
                                    dense: _vm.user.tiivisNakyma,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "TableRow",
                                              {
                                                attrs: {
                                                  clickable: true,
                                                  data: item,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleOpenRow(
                                                      item.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-no-wrap",
                                                  },
                                                  [
                                                    _vm.kirjauksetExpanded.get(
                                                      item.id
                                                    )
                                                      ? _c("v-icon", [
                                                          _vm._v(
                                                            " expand_more "
                                                          ),
                                                        ])
                                                      : !_vm.kirjauksetExpanded.get(
                                                          item.id
                                                        ) &&
                                                        item.kirjaukset.length >
                                                          0
                                                      ? _c("v-icon", [
                                                          _vm._v(
                                                            " chevron_right "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-no-wrap",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.laskunumero
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "max-width" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.lahde) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$date(
                                                            item.maksupaiva
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$date(
                                                            item.kirjauspaiva
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatSumToFixed2"
                                                          )(item.paaoma)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm.showKirjauksetKulu
                                                  ? _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        item.kulu
                                                          ? [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formatSumToFixed2"
                                                                    )(item.kulu)
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                                _vm.showKirjauksetViivkorko
                                                  ? _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-no-wrap text-right",
                                                      },
                                                      [
                                                        item.viivkorko
                                                          ? [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formatSumToFixed2"
                                                                    )(
                                                                      item.viivkorko
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-no-wrap text-right",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "formatSumToFixed2"
                                                          )(item.summa)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.tableKirjauksetOpenRows.includes(
                                              item.id
                                            )
                                              ? _vm._l(
                                                  item.kirjaukset,
                                                  function (kirjaus) {
                                                    return _c(
                                                      "TableRow",
                                                      {
                                                        key: kirjaus.id,
                                                        attrs: {
                                                          "sub-row": true,
                                                        },
                                                      },
                                                      [
                                                        _c("td", {
                                                          staticClass:
                                                            "text-no-wrap",
                                                        }),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap",
                                                          },
                                                          [
                                                            kirjaus.lasku_id > 0
                                                              ? _c(
                                                                  "RouterLinkEhdollinen",
                                                                  {
                                                                    attrs: {
                                                                      ehto: Boolean(
                                                                        kirjaus.lasku_id
                                                                      ),
                                                                      to: {
                                                                        name: "laskutus-nayta",
                                                                        params:
                                                                          {
                                                                            id: kirjaus.lasku_id,
                                                                          },
                                                                      },
                                                                      "custom-contextmenu":
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      contextmenu:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.avaaMenuContext(
                                                                            $event,
                                                                            {
                                                                              id: kirjaus.lasku_id,
                                                                            },
                                                                            "laskutus-nayta"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          kirjaus.laskunumero
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      kirjaus.laskunumero
                                                                    )
                                                                  ),
                                                                ]),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "max-width",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  kirjaus.lahde
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$date(
                                                                    kirjaus.maksupaiva
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$date(
                                                                    kirjaus.kirjauspaiva
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(
                                                                    kirjaus.paaoma
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.showKirjauksetKulu
                                                          ? _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap text-right",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatSumToFixed2"
                                                                      )(
                                                                        kirjaus.kulu
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.showKirjauksetViivkorko
                                                          ? _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-no-wrap text-right",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatSumToFixed2"
                                                                      )(
                                                                        kirjaus.viivkorko
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-no-wrap text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatSumToFixed2"
                                                                  )(
                                                                    kirjaus.summa
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                      _vm.perintaNayta.toimeksianto
                                        .tapahtuma_set.length
                                        ? {
                                            key: "body.append",
                                            fn: function () {
                                              return [
                                                _c("TableRow", [
                                                  _c("td", {
                                                    staticClass: "text-no-wrap",
                                                  }),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: { colspan: "4" },
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "common:total"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-right",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              _vm.perintaNayta
                                                                .toimeksianto
                                                                .kirjattu_paaoma
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm.showKirjauksetKulu
                                                    ? _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatSumToFixed2"
                                                                )(
                                                                  _vm
                                                                    .perintaNayta
                                                                    .toimeksianto
                                                                    .kirjattu_kulu
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.showKirjauksetViivkorko
                                                    ? _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatSumToFixed2"
                                                                )(
                                                                  _vm
                                                                    .perintaNayta
                                                                    .toimeksianto
                                                                    .kirjattu_viivkorko
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-right",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatSumToFixed2"
                                                            )(
                                                              _vm.perintaNayta
                                                                .toimeksianto
                                                                .kirjattu
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          }
                                        : null,
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        this.$permission.checkSinglePerm(
                          "onlinerestapi.onlinerestapi_toimeksianto_kirje"
                        ) && _vm.perintaNayta.toimeksianto.kirje_set.length
                          ? _c(
                              "div",
                              { staticClass: "py-4" },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-6" },
                                  [
                                    _c("CardTitle", {
                                      attrs: {
                                        text: _vm.$t(
                                          "common:collectionShow.letters"
                                        ),
                                      },
                                    }),
                                    _c("DataTableToimeksiantoKirje", {
                                      attrs: {
                                        toimeksianto:
                                          _vm.perintaNayta.toimeksianto,
                                        items:
                                          _vm.perintaNayta.toimeksianto
                                            .kirje_set,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.perintaNayta.toimeksianto.asiakirja_set.length
                          ? _c(
                              "div",
                              { staticClass: "py-4" },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-6" },
                                  [
                                    _c("CardTitle", {
                                      attrs: {
                                        text: _vm.$t("common:attachments"),
                                      },
                                    }),
                                    _c("DataTableToimeksiantoAsiakirja", {
                                      attrs: {
                                        toimeksianto:
                                          _vm.perintaNayta.toimeksianto,
                                        items:
                                          _vm.perintaNayta.toimeksianto
                                            .asiakirja_set,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.lisatiedotItems.length
                          ? _c(
                              "div",
                              { staticClass: "py-4" },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "pa-6" },
                                  [
                                    _c("CardTitle", {
                                      attrs: {
                                        text: _vm.$t("common:additionalInfo"),
                                      },
                                    }),
                                    _c("v-data-table", {
                                      attrs: {
                                        items: _vm.lisatiedotItems,
                                        headers: _vm.tableHeadersLisatiedot,
                                        "hide-default-footer": true,
                                        "items-per-page": 999,
                                        "no-data-text": _vm.$t(
                                          "common:collectionShow.noAdditionalInfos"
                                        ),
                                        dense: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c("TableRow", [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(item.key)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "max-width",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.value) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1355340415
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.tuomioText
                          ? _c(
                              "div",
                              { staticClass: "py-4" },
                              [
                                _c("v-card", { staticClass: "pa-6" }, [
                                  _c(
                                    "p",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "icon--inline purple--text",
                                        },
                                        [_vm._v(" error ")]
                                      ),
                                      _vm._v(
                                        " " + _vm._s(_vm.tuomioText) + " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "3" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-6" },
                            [
                              _c("Timeline", {
                                attrs: {
                                  items: _vm.perintaNayta.toimeksianto.aikajana,
                                  "enable-reply": true,
                                  "salli-muistiinpanojen-muokkaus": false,
                                },
                                on: {
                                  "msg-mark-as-unread": function ($event) {
                                    return _vm.loadItem(true)
                                  },
                                  "msg-mark-as-read": function ($event) {
                                    return _vm.loadItem(true)
                                  },
                                  reply: _vm.avaaDialogViestit,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
      _c(
        "CardDialog",
        {
          attrs: {
            title: _vm.$t("common:collectionShow.informBypass"),
            "content-class": "px-4 pb-4",
            "dialog-auki": _vm.ohisuoritusDialogAuki,
          },
          on: {
            close: function ($event) {
              _vm.ohisuoritusDialogAuki = false
            },
          },
        },
        [
          _c("FormOhisuoritus", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.ohisuoritusFormLoading,
              kohdistukset: _vm.perintaNayta.ohisuoritusKohdistukset,
            },
            on: {
              cancel: function ($event) {
                _vm.ohisuoritusDialogAuki = false
              },
              submit: _vm.ohisuoritusSubmit,
              close: function ($event) {
                _vm.ohisuoritusDialogAuki = false
              },
            },
            model: {
              value: _vm.perintaNayta.ohisuoritusFormData,
              callback: function ($$v) {
                _vm.$set(_vm.perintaNayta, "ohisuoritusFormData", $$v)
              },
              expression: "perintaNayta.ohisuoritusFormData",
            },
          }),
        ],
        1
      ),
      _c("CardDialog", {
        attrs: {
          title: _vm.$t("common:sendMessageHeader", {
            asiaa: "toimeksiantoa " + _vm.perintaNayta.toimeksianto.id,
          }),
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
          "dialog-auki": _vm.uusiViestiDialogAuki,
        },
        on: {
          close: function ($event) {
            _vm.uusiViestiDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _vm.viestit.length
                  ? _c("Timeline", {
                      staticClass: "px-4",
                      attrs: {
                        items: _vm.viestit,
                        "show-title": false,
                        "show-item-titles": false,
                        "show-dates": true,
                        "enable-zoom": false,
                        "enable-direction-toggle": false,
                      },
                      on: {
                        "msg-mark-as-unread": function ($event) {
                          return _vm.loadItem(true)
                        },
                        "msg-mark-as-read": function ($event) {
                          return _vm.loadItem(true)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.uusiViestiFormLoading,
                    "focus-on-update": true,
                    painikkeet: "",
                  },
                  on: {
                    submit: _vm.viestiSubmit,
                    close: function ($event) {
                      _vm.uusiViestiDialogAuki = false
                    },
                  },
                  model: {
                    value: _vm.perintaNayta.uusiViestiFormData,
                    callback: function ($$v) {
                      _vm.$set(_vm.perintaNayta, "uusiViestiFormData", $$v)
                    },
                    expression: "perintaNayta.uusiViestiFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "CardDialog",
        {
          attrs: {
            "dialog-auki": _vm.asiakirjaDialogAuki,
            title: _vm.$t("common:addAttachment"),
          },
          on: {
            close: function ($event) {
              _vm.asiakirjaDialogAuki = false
            },
          },
        },
        [
          _c("FormLiite", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.asiakirjaFormLoading,
              "on-auki": _vm.asiakirjaDialogAuki,
            },
            on: {
              cancel: function ($event) {
                _vm.asiakirjaDialogAuki = false
              },
              submit: _vm.asiakirjaSubmit,
              close: function ($event) {
                _vm.asiakirjaDialogAuki = false
              },
            },
            model: {
              value: _vm.perintaNayta.asiakirjaFormData,
              callback: function ($$v) {
                _vm.$set(_vm.perintaNayta, "asiakirjaFormData", $$v)
              },
              expression: "perintaNayta.asiakirjaFormData",
            },
          }),
        ],
        1
      ),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa": _vm.menuContextNakyvissa,
          "position-x": _vm.menuContextX,
          "position-y": _vm.menuContextY,
          vaihtoehdot: _vm.menuContextVaihtoehdot,
        },
        on: {
          avaaValilehteen: _vm.avaaUudessaValilehdessa,
          "update:return-value": _vm.suljeMenuContext,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }