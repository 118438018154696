<template>
  <div class="perinta-nayta">
    <ViewTopBarBase
      :title="routeTitle"
    >
      <v-menu
        v-if="oikeusAinakinYhteenMenunToiminnoista"
        bottom
        left
        nudge-bottom="40"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="success"
            depressed
            v-on="on"
          >
            {{ $t('common:actions') }}
            <v-icon right>
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <v-list>
            <v-list-item
              v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_toimeksianto_ohisuoritus')"
              :disabled="!ohisuoritusEnabled"
              @click="avaaDialogOhisuoritus"
            >
              <v-list-item-avatar>
                <v-icon>redo</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:collectionShow.informBypass') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti_lahetys')"
              @click="avaaDialogViestit"
            >
              <v-list-item-avatar>
                <v-icon>comment</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:sendMessage') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="avaaDialogAsiakirja"
            >
              <v-list-item-avatar>
                <v-icon>attachment</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t('common:addAttachment') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </ViewTopBarBase>

    <Spinner v-if="tietueLataamassa" />
    <CardPahoittelu v-else-if="tietueLatausEpaonnistunut" />
    <template v-else>
      <v-container
        class="pa-2"
        fluid
      >
        <!-- Tiedot -->
        <div class="pa-4">
          <TietueenPerustiedotCard :paamies="perintaNayta.toimeksianto.paamies">
            <template v-slot:vastaanottaja>
              <div
                v-for="taVe in perintaNayta.toimeksianto.toimeksiantovelallinen_set"
                :key="taVe.id"
                class="mb-4"
              >
                <template v-if="eiOikeuttaNahdaAsiakastaValueVelallinen(perintaNayta.toimeksianto, taVe)">
                  <p class="mb-0 font-weight-bold grey--text darken-2">
                    {{ $t('common:debtor') }}
                  </p>
                  <p class="font-italic">
                    {{ $t('common:notAbleToShowRestriction') }}
                  </p>
                </template>
                <template v-else-if="taVe.osoite.lahde === 'trafi' && trafiLupaNaytaVainNimi && !trafiLupaNaytaKaikki">
                  <p class="mb-0 font-weight-bold grey--text darken-2">
                    {{ taVe.osallisuusasema }} ({{ taVe.vastuuprosentti }} %)
                  </p>
                  <h3>{{ taVe.velallinen.nimi }} ({{ taVe.velallinen.id }})</h3>
                </template>
                <template v-else>
                  <p class="mb-0 font-weight-bold grey--text darken-2">
                    {{ taVe.osallisuusasema }} ({{ taVe.vastuuprosentti }} %)
                  </p>
                  <h3>{{ taVe.velallinen.nimi }} ({{ taVe.velallinen.id }})</h3>
                  <span v-if="taVe.velallinen.syntymapaiva">{{ $t('common:born') }}: {{ $date(taVe.velallinen.syntymapaiva) }}</span>
                  <span v-else-if="taVe.velallinen.tyyppi == 'Y' && taVe.velallinen.tunnus">{{ taVe.velallinen.tunnus }}</span>
                  <OsoiteKappale
                    v-if="taVe.velallinen.postiosoite"
                    :postiosoite="taVe.velallinen.postiosoite.osoite"
                    :postinumero="taVe.velallinen.postiosoite.numero"
                    :postitoimipaikka="taVe.velallinen.postiosoite.toimipaikka"
                  />
                </template>
              </div>
            </template>
            <template v-slot:col2>
              <DefList :bordered="(true)">
                <DefListItem
                  label-class="defList__label--emphasized"
                  value-class="font-weight-bold"
                >
                  <template v-slot:label>
                    {{ $t('common:caseNo') }}
                  </template>
                  <template
                    v-if="perintaNayta.toimeksianto.id"
                    v-slot:value
                  >
                    {{ perintaNayta.toimeksianto.id }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:status') }}
                  </template>
                  <template
                    v-if="perintaNayta.toimeksianto.tila && perintaNayta.toimeksianto.ikoni_ja_vari"
                    v-slot:value
                  >
                    <StatusText
                      :text="perintaNayta.toimeksianto.tila.selite"
                      :color="perintaNayta.toimeksianto.ikoni_ja_vari.vari"
                    />
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:transactionDate') }}
                  </template>
                  <template
                    v-if="perintaNayta.toimeksianto.luotu"
                    v-slot:value
                  >
                    {{ $date(perintaNayta.toimeksianto.luotu) }}
                  </template>
                </DefListItem>
                <DefListItem v-if="perintaNayta.toimeksianto.sopimus">
                  <template v-slot:label>
                    {{ $t('common:contractNumber') }}
                  </template>
                  <template v-slot:value>
                    {{ perintaNayta.toimeksianto.sopimus }}
                  </template>
                </DefListItem>
              </DefList>
            </template>
            <template v-slot:col3>
              <DefList :bordered="(true)">
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:phase') }}
                  </template>
                  <template
                    v-if="perintaNayta.toimeksianto.vaihe"
                    v-slot:value
                  >
                    {{ perintaNayta.toimeksianto.vaihe.nimi }}
                    <template v-if="perintaNayta.toimeksianto.vaihepaiva">
                      ({{ $date(perintaNayta.toimeksianto.vaihepaiva) }})
                    </template>
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:dueDate') }}
                  </template>
                  <template
                    v-if="perintaNayta.toimeksianto.saatavarivi_set.length"
                    v-slot:value
                  >
                    {{ $date(perintaNayta.toimeksianto.saatavarivi_set[0].erapaiva) }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:nextPhase') }}
                  </template>
                  <template
                    v-if="perintaNayta.toimeksianto.seuraava"
                    v-slot:value
                  >
                    {{ perintaNayta.toimeksianto.seuraava.nimi }}
                    <template v-if="perintaNayta.toimeksianto.seuraavapaiva">
                      ({{ $date(perintaNayta.toimeksianto.seuraavapaiva) }})
                    </template>
                  </template>
                </DefListItem>
              </DefList>
            </template>
            <template v-slot:col4>
              <DefList :bordered="(true)">
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:accountConnection') }}
                  </template>
                  <template
                    v-if="perintaNayta.toimeksianto.paamies.maksutieto"
                    v-slot:value
                  >
                    {{ perintaNayta.toimeksianto.paamies.maksutieto.iban }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:paymentReference') }}
                  </template>
                  <template
                    v-if="perintaNayta.toimeksianto.viite"
                    v-slot:value
                  >
                    {{ perintaNayta.toimeksianto.viite }}
                  </template>
                </DefListItem>
                <DefListItem>
                  <template v-slot:label>
                    {{ $t('common:open') }}
                  </template>
                  <template v-slot:value>
                    <strong>{{ perintaNayta.toimeksianto.avoinna | formatSumToFixed2 }}</strong>

                    <template v-if="perintaNayta.toimeksianto.nayta_kuluja_auki_teksti">
                      ({{ $t('common:feesOpen') }})
                    </template>
                  </template>
                </DefListItem>
                <DefListItem v-if="perintaNayta.toimeksianto.pm_asiakasnumero">
                  <template v-slot:label>
                    {{ $t('common:pmCustomerNo') }}
                  </template>
                  <template v-slot:value>
                    {{ perintaNayta.toimeksianto.pm_asiakasnumero }}
                  </template>
                </DefListItem>
              </DefList>
            </template>
          </TietueenPerustiedotCard>
        </div>

        <div class="pa-4">
          <v-row>
            <!-- Left column -->
            <v-col
              cols="12"
              lg="9"
            >
              <!-- Saatavaerittely -->
              <div class="pb-4">
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:collectionShow.receivablesBreakdown')" />
                  <v-data-table
                    :items="saatavaerittelyItems"
                    :headers="tableHeadersSaatavaerittelyFiltered"
                    :hide-default-footer="saatavaerittelyItems.length <= 10"
                    :no-data-text="$t('common:collectionShow.noReceivables')"
                    :dense="user.tiivisNakyma"
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap">
                          <RouterLinkEhdollinen
                            v-if="item.laskunumero"
                            :ehto="Boolean(item.lasku_id)"
                            :to="{ name: 'laskutus-nayta', params: { id: item.lasku_id} }"
                            custom-contextmenu
                            @contextmenu.prevent="avaaMenuContext($event, { id: item.lasku_id }, 'laskutus-nayta')"
                          >
                            {{ item.laskunumero }}
                          </RouterLinkEhdollinen>
                        </td>
                        <td class="text-no-wrap">
                          <template v-if="item.laskupaiva">
                            {{ $date(item.laskupaiva) }}
                          </template>
                        </td>
                        <td class="text-no-wrap">
                          {{ $date(item.erapaiva) }}
                        </td>
                        <td class="text-no-wrap">
                          {{ item.pm_viite }}
                        </td>
                        <td class="max-width">
                          {{ item.selite }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.paaoma | formatSumToFixed2 }}
                        </td>
                        <td
                          v-if="showSaatavaerittelyKulu"
                          class="text-no-wrap text-right"
                        >
                          <template v-if="item.kulu">
                            {{ item.kulu | formatSumToFixed2 }}
                          </template>
                        </td>
                        <td
                          v-if="showSaatavaerittelyViivkorko"
                          class="text-no-wrap text-right"
                        >
                          <template v-if="item.viivkorko">
                            {{ item.viivkorko | formatSumToFixed2 }}
                          </template>
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.avoinna | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                    </template>
                    <template
                      v-if="perintaNayta.toimeksianto.saatavarivi_set.length"
                      v-slot:body.append
                    >
                      <TableRow>
                        <td
                          colspan="5"
                          class="text-no-wrap text-right"
                        >
                          <strong>{{ $t('common:total') }}</strong>
                        </td>
                        <td class="text-right">
                          <strong>{{ perintaNayta.toimeksianto.paaoma | formatSumToFixed2 }}</strong>
                        </td>
                        <td
                          v-if="showSaatavaerittelyKulu"
                          class="text-no-wrap text-right"
                        >
                          <strong>{{ perintaNayta.toimeksianto.kulu | formatSumToFixed2 }}</strong>
                        </td>
                        <td
                          v-if="showSaatavaerittelyViivkorko"
                          class="text-no-wrap text-right"
                        >
                          <strong>{{ perintaNayta.toimeksianto.viivkorko | formatSumToFixed2 }}</strong>
                        </td>
                        <td class="text-no-wrap text-right">
                          <strong>{{ perintaNayta.toimeksianto.avoinna | formatSumToFixed2 }}</strong>
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Maksusopimus -->
              <div
                v-if="perintaNayta.toimeksianto.maksusopimus"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:paymentContract')" />

                  <p
                    v-if="typeof perintaNayta.toimeksianto.maksusopimus === 'string'"
                    class="px-4 py-2"
                  >
                    {{ perintaNayta.toimeksianto.maksusopimus }}
                  </p>
                  <template v-else>
                    <p class="px-4 text-right">
                      {{ $t('common:total') }}: {{ $date(perintaNayta.toimeksianto.maksusopimus.luotu) }}
                    </p>
                    <v-data-table
                      :items="maksusopimusItems"
                      :headers="tableHeadersMaksusopimus"
                      :hide-default-footer="maksusopimusItems.length <= 10"
                      :no-data-text="$t('common:collectionShow.noReceivables')"
                      :dense="user.tiivisNakyma"
                    >
                      <template v-slot:item="{ item }">
                        <TableRow
                          :yellow="perintaNayta.toimeksianto.tila.selite !== $t('common:paid') && item.erapaiva_mennyt && item.avoinna > 0"
                          :green="item.avoinna === 0"
                        >
                          <td class="max-width text-no-wrap">
                            {{ item.era }}
                          </td>
                          <td class="text-no-wrap text-right">
                            <template v-if="item.erapaiva">
                              {{ $date(item.erapaiva) }}
                            </template>
                          </td>
                          <td class="text-no-wrap text-right">
                            <template v-if="item.maksupaiva">
                              {{ $date(item.maksupaiva) }}
                            </template>
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ item.summa | formatSumToFixed2 }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ item.avoinna | formatSumToFixed2 }}
                          </td>
                        </TableRow>
                      </template>
                      <template v-slot:body.append>
                        <TableRow>
                          <td
                            colspan="3"
                            class="text-no-wrap text-right"
                          >
                            <strong>{{ $t('common:total') }}</strong>
                          </td>
                          <td class="text-right">
                            <strong>{{ perintaNayta.toimeksianto.maksusopimus.summa | formatSumToFixed2 }}</strong>
                          </td>
                          <td class="text-no-wrap text-right">
                            <strong>{{ perintaNayta.toimeksianto.maksusopimus.avoinna | formatSumToFixed2 }}</strong>
                          </td>
                        </TableRow>
                      </template>
                    </v-data-table>
                  </template>
                </v-card>
              </div>

              <!-- Kirjaukset -->
              <div class="py-4">
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:transactions')" />

                  <v-data-table
                    :items="kirjauksetItems"
                    :headers="tableHeadersKirjauksetFiltered"
                    :hide-default-footer="kirjauksetItems.length <= 10"
                    :no-data-text="$t('common:noTransactions')"
                    :dense="user.tiivisNakyma"
                  >
                    <template v-slot:item="{ item }">
                      <TableRow
                        :clickable="(true)"
                        :data="item"
                        @click="toggleOpenRow(item.id)"
                      >
                        <td class="text-no-wrap">
                          <v-icon v-if="kirjauksetExpanded.get(item.id)">
                            expand_more
                          </v-icon>
                          <v-icon v-else-if="!kirjauksetExpanded.get(item.id) && item.kirjaukset.length > 0">
                            chevron_right
                          </v-icon>
                        </td>
                        <td class="text-no-wrap">
                          {{ item.laskunumero }}
                        </td>
                        <td class="max-width">
                          {{ item.lahde }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.maksupaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ $date(item.kirjauspaiva) }}
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.paaoma | formatSumToFixed2 }}
                        </td>
                        <td
                          v-if="showKirjauksetKulu"
                          class="text-no-wrap text-right"
                        >
                          <template v-if="item.kulu">
                            {{ item.kulu | formatSumToFixed2 }}
                          </template>
                        </td>
                        <td
                          v-if="showKirjauksetViivkorko"
                          class="text-no-wrap text-right"
                        >
                          <template v-if="item.viivkorko">
                            {{ item.viivkorko | formatSumToFixed2 }}
                          </template>
                        </td>
                        <td class="text-no-wrap text-right">
                          {{ item.summa | formatSumToFixed2 }}
                        </td>
                      </TableRow>
                      <template v-if="tableKirjauksetOpenRows.includes(item.id)">
                        <TableRow
                          v-for="kirjaus in item.kirjaukset"
                          :key="kirjaus.id"
                          :sub-row="(true)"
                        >
                          <td class="text-no-wrap">
                          </td>
                          <td class="text-no-wrap">
                            <RouterLinkEhdollinen
                              v-if="kirjaus.lasku_id > 0"
                              :ehto="Boolean(kirjaus.lasku_id)"
                             :to="{ name: 'laskutus-nayta', params: { id: kirjaus.lasku_id} }"
                              custom-contextmenu
                              @contextmenu.prevent="avaaMenuContext($event, { id: kirjaus.lasku_id }, 'laskutus-nayta')"
                            >
                              {{ kirjaus.laskunumero }}
                            </RouterLinkEhdollinen>
                            <span v-else>{{ kirjaus.laskunumero }}</span>
                          </td>
                          <td class="max-width">
                            {{ kirjaus.lahde }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ $date(kirjaus.maksupaiva) }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ $date(kirjaus.kirjauspaiva) }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ kirjaus.paaoma | formatSumToFixed2 }}
                          </td>
                          <td
                            v-if="showKirjauksetKulu"
                            class="text-no-wrap text-right"
                          >
                            {{ kirjaus.kulu | formatSumToFixed2 }}
                          </td>
                          <td
                            v-if="showKirjauksetViivkorko"
                            class="text-no-wrap text-right"
                          >
                            {{ kirjaus.viivkorko | formatSumToFixed2 }}
                          </td>
                          <td class="text-no-wrap text-right">
                            {{ kirjaus.summa | formatSumToFixed2 }}
                          </td>
                        </TableRow>
                      </template>
                    </template>
                    <template
                      v-if="perintaNayta.toimeksianto.tapahtuma_set.length"
                      v-slot:body.append
                    >
                      <TableRow>
                        <td class="text-no-wrap">
                        </td>
                        <td
                          colspan="4"
                          class="text-right"
                        >
                          <strong>{{ $t('common:total') }}</strong>
                        </td>
                        <td class="text-right">
                          <strong>{{ perintaNayta.toimeksianto.kirjattu_paaoma | formatSumToFixed2 }}</strong>
                        </td>
                        <td
                          v-if="showKirjauksetKulu"
                          class="text-right"
                        >
                          <strong>{{ perintaNayta.toimeksianto.kirjattu_kulu | formatSumToFixed2 }}</strong>
                        </td>
                        <td
                          v-if="showKirjauksetViivkorko"
                          class="text-right"
                        >
                          <strong>{{ perintaNayta.toimeksianto.kirjattu_viivkorko | formatSumToFixed2 }}</strong>
                        </td>
                        <td class="text-right">
                          <strong>{{ perintaNayta.toimeksianto.kirjattu | formatSumToFixed2 }}</strong>
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Kirjeet -->
              <div
                v-if="this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_toimeksianto_kirje') &&perintaNayta.toimeksianto.kirje_set.length"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:collectionShow.letters')" />
                  <DataTableToimeksiantoKirje
                    :toimeksianto="perintaNayta.toimeksianto"
                    :items="perintaNayta.toimeksianto.kirje_set"
                  />
                </v-card>
              </div>

              <!-- Liitteet -->
              <div
                v-if="perintaNayta.toimeksianto.asiakirja_set.length"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:attachments')" />
                  <DataTableToimeksiantoAsiakirja
                    :toimeksianto="perintaNayta.toimeksianto"
                    :items="perintaNayta.toimeksianto.asiakirja_set"
                  />
                </v-card>
              </div>

              <!-- Lisätiedot -->
              <div
                v-if="lisatiedotItems.length"
                class="py-4"
              >
                <v-card class="pa-6">
                  <CardTitle :text="$t('common:additionalInfo')" />

                  <v-data-table
                    :items="lisatiedotItems"
                    :headers="tableHeadersLisatiedot"
                    :hide-default-footer="(true)"
                    :items-per-page="999"
                    :no-data-text="$t('common:collectionShow.noAdditionalInfos')"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <TableRow>
                        <td class="text-no-wrap">
                          <strong>{{ item.key }}</strong>
                        </td>
                        <td class="max-width">
                          {{ item.value }}
                        </td>
                      </TableRow>
                    </template>
                  </v-data-table>
                </v-card>
              </div>

              <!-- Tuomio -->
              <div
                v-if="tuomioText"
                class="py-4"
              >
                <v-card class="pa-6">
                  <p>
                    <v-icon class="icon--inline purple--text">
                      error
                    </v-icon>
                    {{ tuomioText }}
                  </p>
                </v-card>
              </div>
            </v-col>

            <!-- Right column -->
            <v-col
              cols="12"
              lg="3"
            >
              <!-- Timeline -->
              <v-card class="pa-6">
                <Timeline
                  :items="perintaNayta.toimeksianto.aikajana"
                  :enable-reply="(true)"
                  :salli-muistiinpanojen-muokkaus="(false)"
                  @msg-mark-as-unread="loadItem(true)"
                  @msg-mark-as-read="loadItem(true)"
                  @reply="avaaDialogViestit"
                />
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>

    <!-- Dialog: Ohisuoritus -->
    <CardDialog
      :title="$t('common:collectionShow.informBypass')"
      content-class="px-4 pb-4"
      :dialog-auki="ohisuoritusDialogAuki"
      @close="(ohisuoritusDialogAuki = false)"
    >
      <FormOhisuoritus
        v-model="perintaNayta.ohisuoritusFormData"
        class="pa-4"
        :loading="ohisuoritusFormLoading"
        :kohdistukset="perintaNayta.ohisuoritusKohdistukset"
        @cancel="ohisuoritusDialogAuki = false"
        @submit="ohisuoritusSubmit"
        @close="(ohisuoritusDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Viestit -->
    <CardDialog
      :title="$t('common:sendMessageHeader', { asiaa: 'toimeksiantoa ' + perintaNayta.toimeksianto.id })"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      :dialog-auki="uusiViestiDialogAuki"
      @close="(uusiViestiDialogAuki = false)"
    >
      <template v-slot:default>
        <Timeline
          v-if="viestit.length"
          class="px-4"
          :items="viestit"
          :show-title="(false)"
          :show-item-titles="(false)"
          :show-dates="(true)"
          :enable-zoom="(false)"
          :enable-direction-toggle="(false)"
          @msg-mark-as-unread="loadItem(true)"
          @msg-mark-as-read="loadItem(true)"
        />
      </template>
      <template v-slot:footer>
        <FormUusiViesti
          v-model="perintaNayta.uusiViestiFormData"
          :loading="uusiViestiFormLoading"
          :focus-on-update="(true)"
          painikkeet
          @submit="viestiSubmit"
          @close="(uusiViestiDialogAuki = false)"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Lisää liite -->
    <CardDialog
      :dialog-auki="asiakirjaDialogAuki"
      :title="$t('common:addAttachment')"
      @close="(asiakirjaDialogAuki = false)"
    >
      <FormLiite
        v-model="perintaNayta.asiakirjaFormData"
        class="pa-4"
        :loading="asiakirjaFormLoading"
        :on-auki="asiakirjaDialogAuki"
        @cancel="asiakirjaDialogAuki = false"
        @submit="asiakirjaSubmit"
        @close="(asiakirjaDialogAuki = false)"
      />
    </CardDialog>

    <!-- Dialog: Välilehti -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import {
  TableHeadersSaatavaerittely,
  TableHeadersMaksusopimus,
  TableHeadersKirjaukset,
  TableHeadersLisatiedot,
  //TableHeadersToimeksiantoAsiakirja,
} from '@/utils/tableHeaders'
import {
  DataTableToimeksiantoAsiakirja,
  DataTableToimeksiantoKirje,
  FormLiite,
  FormOhisuoritus,
  FormUusiViesti,
  OsoiteKappale,
  StatusText,
  TietueenPerustiedotCard,
  Timeline,
} from '@/components'
import MenuContextMixin from '@/mixins/MenuContextMixin'
import TrafiLupaMixin from '@/mixins/TrafiLupaMixin'

export default {
  name: 'PerintaNayta',
  components: {
    DataTableToimeksiantoAsiakirja,
    DataTableToimeksiantoKirje,
    FormLiite,
    FormOhisuoritus,
    FormUusiViesti,
    OsoiteKappale,
    StatusText,
    TietueenPerustiedotCard,
    Timeline,
  },
  mixins: [
    MenuContextMixin,
    TrafiLupaMixin,
  ],
  data () {
    return {
      tietueLataamassa: false,
      tietueLatausEpaonnistunut: false,
      asiakirjaDialogAuki: false,
      asiakirjaFormLoading: false,
      ohisuoritusDialogAuki: false,
      ohisuoritusFormLoading: false,
      tableHeadersKirjaukset: TableHeadersKirjaukset || [],
      tableHeadersLisatiedot: TableHeadersLisatiedot || [],
      tableHeadersMaksusopimus: TableHeadersMaksusopimus || [],
      tableHeadersSaatavaerittely: TableHeadersSaatavaerittely || [],
      tableKirjauksetOpenRows: [],
      laskuNroId: new Map(),
      uusiViestiDialogAuki: false,
      uusiViestiFormLoading: false,
      kirjauksetExpanded: new Map(),
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      perintaNayta: state => state.perintaNayta,
    }),
    getEiKirjauksia () {
      return this.$t('common:noTransactions')
    },

    tableHeadersSaatavaerittelyFiltered () {
      return this.tableHeadersSaatavaerittely.filter(item => {
        if (!this.showSaatavaerittelyKulu && item.value == 'kulu') return false
        if (!this.showSaatavaerittelyViivkorko && item.value == 'viivkorko') return false
        return item
      })
    },

    showSaatavaerittelyKulu () {
      return this.saatavaerittelyItems.some(item => item.kulu)
    },

    showSaatavaerittelyViivkorko () {
      return this.saatavaerittelyItems.some(item => item.viivkorko)
    },

    tableHeadersKirjauksetFiltered () {
      return this.tableHeadersKirjaukset.filter(item => {
        if (!this.showKirjauksetKulu && item.value == 'kulu') return false
        if (!this.showKirjauksetViivkorko && item.value == 'viivkorko') return false

        return item
      })
    },

    showKirjauksetKulu () {
      return this.kirjauksetItems.some(item => {
        return item.kulu || item.kirjaukset.some(kirjaus => kirjaus.kulu)
      })
    },

    showKirjauksetViivkorko () {
      return this.kirjauksetItems.some(item => {
        return item.viivkorko || item.kirjaukset.some(kirjaus => kirjaus.viivkorko)
      })
    },

    ohisuoritusEnabled () {
      return this.perintaNayta.toimeksianto.avoinna_paaoma > 0
    },

    saatavaerittelyItems () {
      let saatavaerittelyItems = []
      let kuluriviItems = []

      if (this.perintaNayta.toimeksianto.saatavarivi_set) {
        saatavaerittelyItems = this.perintaNayta.toimeksianto.saatavarivi_set.map(item => {
          this.laskuNroId.set(item.laskunumero, item.lasku_id)
          return {
            laskunumero: item.laskunumero,
            lasku_id: item.lasku_id,
            laskupaiva: item.laskupaiva,
            erapaiva: item.erapaiva,
            pm_viite: item.pm_viite,
            selite: item.selite,
            paaoma: item.paaoma,
            avoinna: item.avoinna,
            viivkorko: item.viivkorko,
          }
        })
      }

      if (this.perintaNayta.toimeksianto.kulurivi_set) {
        kuluriviItems = this.perintaNayta.toimeksianto.kulurivi_set.map(item => {
          return {
            laskunumero: null,
            laskupaiva: item.kirjauspaiva,
            erapaiva: item.erapaiva,
            selite: item.selite,
            kulu: item.summa,
            viivkorko: item.viivkorko,
            avoinna: item.avoinna,
          }
        })
      }

      return [...saatavaerittelyItems, ...kuluriviItems]
    },

    maksusopimusItems () {
      if (!this.perintaNayta.toimeksianto.maksusopimus || typeof this.perintaNayta.toimeksianto.maksusopimus !== 'object') return []

      let counter = 1

      return this.perintaNayta.toimeksianto.maksusopimus.eraerittely.map(item => {
        return {
          era: counter++,
          erapaiva: item.erapaiva,
          erapaiva_mennyt: item.erapaiva_mennyt,
          maksupaiva: item.maksupaiva,
          summa: item.summa,
          avoinna: item.avoinna,
        }
      })
    },

    kirjauksetItems () {
      return this.perintaNayta.toimeksianto.tapahtuma_set.map(tapahtuma => {
        return {
          id: tapahtuma.id,
          laskunumero: tapahtuma.maksaja,
          lahde: tapahtuma.tilityyppi,
          maksupaiva: tapahtuma.maksupaiva,
          kirjauspaiva: tapahtuma.kirjauspaiva,
          paaoma: tapahtuma.kirjattu_paaoma,
          kulu: tapahtuma.kirjattu_kulu,
          viivkorko: tapahtuma.kirjattu_viivkorko,
          summa: tapahtuma.kirjattu,
          kirjaukset: tapahtuma.kirjaus_set.map(kirjaus => {
            if (kirjaus.saatavakirjaus) {
              return {
                id: kirjaus.id,
                laskunumero: kirjaus.saatavakirjaus.laskunumero,
                lasku_id: this.laskuNroId.get(kirjaus.saatavakirjaus.laskunumero),
                lahde: kirjaus.saatavakirjaus.lahde,
                maksupaiva: null,
                kirjauspaiva: kirjaus.kirjauspaiva,
                paaoma: kirjaus.summa,
                kulu: null,
                viivkorko: null,
                summa: kirjaus.summa,
              }
            } else if (kirjaus.kulukirjaus) {
              return {
                id: kirjaus.id,
                laskunumero: kirjaus.kulukirjaus.selite,
                lasku_id: "",
                lahde: kirjaus.kulukirjaus.lahde,
                maksupaiva: null,
                kirjauspaiva: kirjaus.kirjauspaiva,
                paaoma: null,
                kulu: kirjaus.summa,
                viivkorko: null,
                summa: kirjaus.summa,
              }
            } else if (kirjaus.viivkorkokirjaus) {
              return {
                id: kirjaus.id,
                laskunumero: kirjaus.viivkorkokirjaus.laskunumero,
                lasku_id: this.laskuNroId.get(kirjaus.viivkorkokirjaus.laskunumero),
                lahde: kirjaus.viivkorkokirjaus.lahde,
                maksupaiva: null,
                kirjauspaiva: kirjaus.kirjauspaiva,
                paaoma: null,
                kulu: null,
                viivkorko: kirjaus.summa,
                summa: kirjaus.summa,
              }
            }
          })
        }
      })
    },

    lisatiedotItems () {
      if (!this.perintaNayta.toimeksianto.toimeksiantolisatieto_set) return []

      return this.perintaNayta.toimeksianto.toimeksiantolisatieto_set.map(item => {
        return {
          key: item.key,
          value: item.value,
        }
      })
    },

    tuomioText () {
      if (!this.perintaNayta.toimeksianto.tuomio) return null

      let segments = []

      if (this.perintaNayta.toimeksianto.tuomio.paatospaiva) segments.push(this.$t('common:collectionShow.judgmentGiven') + ' ' + this.$date(this.perintaNayta.toimeksianto.tuomio.paatospaiva))
      if (this.perintaNayta.toimeksianto.tuomio.diaarinumero) segments.push(this.$t('common:collectionShow.diaryNumber') + ' ' + this.perintaNayta.toimeksianto.tuomio.diaarinumero)
      if (this.perintaNayta.toimeksianto.tuomio.ratkaisunumero) segments.push(this.$t('common:collectionShow.solutionNumber') + ' ' + this.perintaNayta.toimeksianto.tuomio.ratkaisunumero)

      return segments.join('. ') + '.'
    },

    viestit () {
      return this.perintaNayta.toimeksianto.aikajana.filter(item => item.tyyppi == 'PaamiesViesti')
    },

    routeTitle () {
      if (this.perintaNayta.toimeksianto.id) {
        return `${this.$route.meta.title} ${this.perintaNayta.toimeksianto.id}`
      } else {
        return this.$route.meta.title
      }
    },

    oikeusAinakinYhteenMenunToiminnoista () {
      return (
        this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_toimeksianto_ohisuoritus') ||
        this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti_lahetys')
      )
    },
  },
  async mounted () {
    await this.loadItem()
  },
  methods: {
    async loadItem (silent = false) {
      try {
        if (!silent) this.tietueLataamassa = true
        await this.$store.dispatch('perintaNayta/loadItem', this.$route)
      } catch (e) {
        if (e.request && e.request.result && e.request.result.status === 404) {
          this.$naytaVirheilmoitus(this.$t('common:collectionShow.assignmentNotFound'))

          this.$router.replace({ name: 'error404' })
        } else {
          this.tietueLatausEpaonnistunut = true
          this.$oletusVirheenkasittely(e, this.$t('common:noSuccessTryAgain'))
        }
      } finally {
        this.tietueLataamassa = false
      }
    },

    avaaDialogOhisuoritus () {
      // Prepare form
      this.$store.dispatch('perintaNayta/alustaOhisuoritusFormData')
      // Show dialog
      this.ohisuoritusDialogAuki = true
    },

    async ohisuoritusSubmit () {
      try {
        this.ohisuoritusFormLoading = true
        await this.$store.dispatch('perintaNayta/ohisuoritusSubmit')
        this.$naytaOnnistumisilmoitus(this.$t('common:collectionShow.bypassRegistered'))
        this.ohisuoritusDialogAuki = false
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.ohisuoritusFormLoading = false
      }
    },

    avaaDialogViestit () {
      this.uusiViestiDialogAuki = true
      this.perintaNayta.uusiViestiFormData = {}
    },

    async viestiSubmit () {
      try {
        this.uusiViestiFormLoading = true
        await this.$store.dispatch('perintaNayta/viestiSubmit')
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:noSuccessTryAgainMessage'))
      } finally {
        this.uusiViestiFormLoading = false
      }
    },

    avaaDialogAsiakirja () {
      this.perintaNayta.asiakirjaFormData = {}
      this.asiakirjaDialogAuki = true
    },

    async asiakirjaSubmit () {
      try {
        this.asiakirjaFormLoading = true
        await this.$store.dispatch('perintaNayta/asiakirjaSubmit')
        this.$naytaOnnistumisilmoitus(this.$t('common:attachmentSuccessfullyAdded') )
        this.asiakirjaDialogAuki = false
      } catch (e) {
        let virheviesti = ''

        if (e.name == this.$SallittuKokoYlitettyError.name) {
          virheviesti = this.$t('common:attachmentSizeExceeded', { filename: this.perintaNayta.asiakirjaFormData.tiedosto.name })
        } else {
          virheviesti = this.$t('common:attachmentSendingFailed')
        }
        this.$oletusVirheenkasittely(e, virheviesti)
      } finally {
        this.asiakirjaFormLoading = false
      }
    },

    toggleOpenRow (id = null) {
      if (!id) return

      const index = this.tableKirjauksetOpenRows.indexOf(id)

      if (index > -1) {
        this.kirjauksetExpanded.set(id, false)
        this.tableKirjauksetOpenRows.splice(index, 1)
      } else {
        this.kirjauksetExpanded.set(id, true)
        this.tableKirjauksetOpenRows.push(id)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
