var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "perinta-uusi grey lighten-2" }, [
    _c(
      "div",
      { staticClass: "sticky" },
      [
        _c(
          "v-toolbar",
          {
            staticClass: "v-toolbar--sticky pl-6",
            attrs: { color: "primary", flat: "", tabs: "", dark: true },
          },
          [
            _c(
              "v-btn",
              { attrs: { to: { name: "perinta-selaa" }, icon: "" } },
              [_c("v-icon", [_vm._v("arrow_back")])],
              1
            ),
            _c("v-toolbar-title", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("common:collectionNew.createNewAssignment")) +
                  " "
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "px-6 py-12 main-div" },
      [
        _vm.luotuToimeksianto.id
          ? [
              _c(
                "v-container",
                { staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12" },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-6", attrs: { justify: "center" } },
                    [
                      _c("v-tooltip", {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c("StatusIcon", {
                                    attrs: {
                                      icon: "invoice-lightning",
                                      size: "large",
                                      "hover-icon": "open-in-new",
                                      "bg-color": "secondary",
                                      "event-listener": on,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "perinta-nayta",
                                          params: {
                                            id: _vm.luotuToimeksianto.id,
                                          },
                                        })
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("common:openAssignment"))
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2886521589
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pa-6", attrs: { justify: "center" } },
                    [
                      _c(
                        "span",
                        { staticClass: "title" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("common:collectionNew.assignmentSaved")
                            ) + " "
                          ),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "perinta-nayta",
                                  params: { id: _vm.luotuToimeksianto.id },
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.luotuToimeksianto.id))]
                          ),
                          _vm._v(". "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pa-6", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { large: "", to: { name: "perinta-selaa" } },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:collectionNew.returnToAssignmentSearch"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: { large: "", color: "success" },
                          on: { click: _vm.palautaAlkutilaan },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:collectionNew.createNewAssignment"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          : [
              _c(
                "div",
                { staticClass: "paper px-6 py-12 mb-12" },
                [
                  _c("FormToimeksianto", {
                    ref: "formToimeksianto",
                    on: {
                      muokkaus: function ($event) {
                        _vm.naytaPoistumisvahvistus = true
                      },
                    },
                    model: {
                      value: _vm.toimeksianto,
                      callback: function ($$v) {
                        _vm.toimeksianto = $$v
                      },
                      expression: "toimeksianto",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "max-width--1200 px-6 py-4 mx-auto mb-12",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-5",
                      attrs: {
                        large: "",
                        color: "success",
                        disabled: !_vm.toimeksianto.paamies.id,
                      },
                      on: { click: _vm.luoToimeksianto },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("common:collectionNew.createAssignment")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }