var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "perinta-selaa" },
    [
      _c(
        "v-navigation-drawer",
        {
          ref: "offCanvas",
          attrs: { width: "450", app: "", right: "", temporary: "" },
          model: {
            value: _vm.showOffCanvas,
            callback: function ($$v) {
              _vm.showOffCanvas = $$v
            },
            expression: "showOffCanvas",
          },
        },
        [
          _vm.loadingOffCanvas
            ? _c("Spinner")
            : _c("OffCanvasToimeksianto", {
                on: {
                  "close-button-click": _vm.setShowOffCanvas,
                  messageSubmit: _vm.refresh,
                  "msg-mark-as-unread": _vm.refresh,
                  "msg-mark-as-read": _vm.refresh,
                },
                model: {
                  value: _vm.selectedItem.item,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedItem, "item", $$v)
                  },
                  expression: "selectedItem.item",
                },
              }),
        ],
        1
      ),
      _c("ViewSelaaTopBarBase", {
        attrs: {
          "pikahaun-placeholder-teksti": _vm.$t(
            "common:collectionBrowse.quickSearch"
          ),
          "vuex-module-name": _vm.vuexModuleName,
        },
        on: {
          "open-search-panel": _vm.openSearchPanel,
          "close-search-panel": _vm.closeSearchPanel,
        },
      }),
      _c("ViewSelaaTabs", {
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          styleClass: "font-size:12px",
        },
      }),
      _c("TransitionSlidePanel", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchPanelOpen,
                expression: "searchPanelOpen",
              },
            ],
            staticClass: "pa-1 primary darken-1 shadow--inset",
          },
          [
            _c("InfoValittuValilehti", {
              attrs: { "current-tab": _vm.currentTab },
            }),
            _c("FormSearchPerinta", {
              model: {
                value: _vm.searchTerms,
                callback: function ($$v) {
                  _vm.searchTerms = $$v
                },
                expression: "searchTerms",
              },
            }),
          ],
          1
        ),
      ]),
      _c("ViewSelaaToolbar", {
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          "item-partitiivi": _vm.$t("common:collectionBrowse.assignments"),
          "lataa-csv-btn-config": {
            csvData: _vm.csvData,
            csvLabels: _vm.csvLabels,
            csvTitle: _vm.csvTitle,
          },
          "uusi-btn-config": {
            toName: "perinta-uusi",
            itemName: "toimeksianto",
            permission: _vm.onkoLupaLuodaToimeksianto,
          },
        },
      }),
      _c("v-data-table", {
        staticClass: "v-data-table--striped",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.items,
          "hide-default-footer": _vm.items.length < 10,
          "footer-props": {
            itemsPerPageOptions: [10, 25, 50, 100],
          },
          "multi-sort": true,
          "server-items-length": _vm.itemsTotal,
          options: _vm.pagination,
          loading: _vm.loading,
          "loading-text": "",
          "no-data-text": "",
          dense: _vm.tiivisNakyma,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "TableRow",
                  {
                    attrs: {
                      clickable: true,
                      data: item,
                      "selected-item": _vm.selectedItem.item,
                      fade: _vm.loading,
                      "custom-contextmenu": true,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSelectedItem({ item: item })
                      },
                      contextmenu: function ($event) {
                        $event.preventDefault()
                        return _vm.avaaMenuContext($event, item)
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c("StatusIcon", {
                                      attrs: {
                                        icon: item.ikoni_ja_vari.ikoni,
                                        size: _vm.tiivisNakyma
                                          ? "small"
                                          : "medium",
                                        "hover-icon": "open-in-new",
                                        "bg-color": item.ikoni_ja_vari.vari,
                                        "event-listener": on,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "perinta-nayta",
                                            params: { id: item.id },
                                          })
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("common:openAssignment"))
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.id) + " "),
                    ]),
                    _c(
                      "td",
                      { staticClass: "max-width" },
                      [
                        _vm._l(
                          item.toimeksiantovelallinen_set,
                          function (taVe) {
                            return [
                              _vm.eiOikeuttaNahdaAsiakastaValueVelallinen(
                                item,
                                taVe
                              )
                                ? [
                                    _c(
                                      "span",
                                      {
                                        key: taVe.id,
                                        staticClass: "font-italic",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common:notAbleToShowRestriction"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(taVe.velallinen.nimi) +
                                        " (" +
                                        _vm._s(taVe.velallinen.id) +
                                        ") "
                                    ),
                                  ],
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      [
                        item.tila
                          ? [_vm._v(" " + _vm._s(item.tila.selite) + " ")]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      [
                        item.tila && item.vaihe
                          ? [
                              _c(
                                "span",
                                {
                                  class:
                                    item.tila.value == "XX" ||
                                    item.tila.value == "PE" ||
                                    item.tila.value == "LO" ||
                                    item.tila.value == "MY"
                                      ? "strikethrough"
                                      : "",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.vaihe.vaihe) +
                                      ": " +
                                      _vm._s(item.vaihe.nimi) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(item.paamies.nimi) +
                          " (" +
                          _vm._s(item.paamies.id) +
                          ") "
                      ),
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-no-wrap text-right" },
                      [
                        item.saatavarivi_set.length
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(item.saatavarivi_set[0].laskunumero) +
                                  " "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "td",
                      { staticClass: "text-no-wrap text-right" },
                      [
                        item.saatavarivi_set.length
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$date(
                                      item.saatavarivi_set[0].laskupaiva
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "td",
                      { staticClass: "text-no-wrap text-right" },
                      [
                        item.saatavarivi_set.length
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$date(item.saatavarivi_set[0].erapaiva)
                                  ) +
                                  " "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatSumToFixed2")(item.avoinna)) +
                          " "
                      ),
                      item.nayta_kuluja_auki_teksti
                        ? _c("span", { staticClass: "text-no-wrap" }, [
                            _vm._v(
                              " (" + _vm._s(_vm.$t("common:feesOpen")) + ")"
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.tyhjaTaulukko
        ? _c("TyhjaTaulukkoOtsikko", {
            attrs: { otsikko: _vm.$t("youHaveNoAssignments") },
          })
        : _vm._e(),
      _vm.tyhjaTaulukko
        ? _c("ToolbarTyhjaTaulukko", {
            attrs: {
              ikoni: "library_add",
              taustavari: "success",
              teksti: _vm.$t("newAssignment"),
              infoteksti: _vm.$t("noAssignmentAddInfo"),
              naytaAina: true,
              "uusi-btn-config": {
                toName: "perinta-uusi",
                itemName: "toimeksianto",
                permission: _vm.onkoLupaLuodaToimeksianto,
              },
            },
          })
        : _vm._e(),
      _vm.pagesTotal > 1
        ? _c(
            "v-toolbar",
            { staticClass: "my-6 py-2 transparent", attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c("Pagination", {
                attrs: { length: _vm.pagesTotal, disabled: _vm.loading },
                model: {
                  value: _vm.pagination.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "page", $$v)
                  },
                  expression: "pagination.page",
                },
              }),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa": _vm.menuContextNakyvissa,
          "position-x": _vm.menuContextX,
          "position-y": _vm.menuContextY,
          vaihtoehdot: _vm.menuContextVaihtoehdot,
        },
        on: {
          avaaValilehteen: _vm.avaaUudessaValilehdessa,
          "update:return-value": _vm.suljeMenuContext,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }