<template>
  <div class="perinta-uusi grey lighten-2">
    <!-- Top bar -->
    <div class="sticky">
      <v-toolbar
        class="v-toolbar--sticky pl-6"
        color="primary"
        flat
        tabs
        :dark="true"
      >
        <v-btn
          :to="{ name: 'perinta-selaa' }"
          icon
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t('common:collectionNew.createNewAssignment') }}
        </v-toolbar-title>
      </v-toolbar>
    </div>


    <div class="px-6 py-12 main-div">
      <!-- Toimeksiannon luomisen jälkeinen valikko -->
      <template v-if="luotuToimeksianto.id">
        <v-container class="max-width--1200 px-6 py-4 mx-auto mb-12">
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  icon="invoice-lightning"
                  size="large"
                  :hover-icon="('open-in-new')"
                  bg-color="secondary"
                  :event-listener="on"
                  @click="$router.push({ name: 'perinta-nayta', params: { id: luotuToimeksianto.id } })"
                />
              </template>
              <template v-slot:default>
                <span>{{ $t('common:openAssignment') }}</span>
              </template>
            </v-tooltip>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <span class="title">{{ $t('common:collectionNew.assignmentSaved') }} <router-link :to="{ name: 'perinta-nayta', params: { id: luotuToimeksianto.id } }">{{ luotuToimeksianto.id }}</router-link>.
            </span>
          </v-row>
          <v-row
            justify="center"
            class="pa-6"
          >
            <v-btn
              large
              :to="{ name: 'perinta-selaa' }"
              class="mr-2"
            >
              {{ $t('common:collectionNew.returnToAssignmentSearch') }}
            </v-btn>
            <v-btn
              large
              color="success"
              class="ml-2"
              @click="palautaAlkutilaan"
            >
              {{ $t('common:collectionNew.createNewAssignment') }}
            </v-btn>
          </v-row>
        </v-container>
      </template>


      <!-- Toimeksiannon tiedot -->
      <template v-else>
        <div class="paper px-6 py-12 mb-12">
          <FormToimeksianto
            ref="formToimeksianto"
            v-model="toimeksianto"
            @muokkaus="naytaPoistumisvahvistus = true"
          />
        </div>

        <!-- Tallennus ja esikatselu -->
        <v-row
          justify="center"
          class="max-width--1200 px-6 py-4 mx-auto mb-12"
        >
          <v-btn
            large
            color="success"
            class="mx-5"
            :disabled="!toimeksianto.paamies.id"
            @click="luoToimeksianto"
          >
            {{ $t('common:collectionNew.createAssignment') }}
          </v-btn>
        </v-row>
      </template>
    </div>

    <!-- <template :v-if="process.env.VUE_APP_DEV_MODE== 1"><pre>{{ toimeksianto }}</pre></template> -->
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { Toimeksianto } from '@/class/Toimeksianto'
import {
  FormToimeksianto,
} from '@/components'
import PoistumisvahvistusMixin from '@/mixins/PoistumisvahvistusMixin'

export default {
  name: 'PerintaUusi',
  components: {
    FormToimeksianto,
  },
  mixins: [PoistumisvahvistusMixin],
  data () {
    return {
      velallinenDialogAuki: false,
    }
  },
  computed: {
    ...mapState({
      kenttienValinnat: state => state.perintaUusi.kenttienValinnat,
      luotuToimeksianto: state => state.perintaUusi.luotuToimeksianto,
      toimeksianto: state => state.perintaUusi.toimeksianto,
    }),
    tallennusOnnistuiTeksti () {
      return this.luotuToimeksianto.kasitelty ? this.$t('common:assignmentSavedHandled') : this.$t('common:assignmentSaved')
    }
  },
  watch: {
    'toimeksianto.velallinen.id': {
      async handler (id) {
        this.haeVelallisenKenttienValinnat(id)
      }
    },
  },
  created () {
    this.palautaAlkutilaan()
    this.haeVelallisenKenttienValinnat()
  },
  mounted () {
    setTimeout(() => {
      this.odotusaikaYlitetty = true
    }, 2000)
  },
  methods: {
    async luoToimeksianto () {
      if (!this.$refs.formToimeksianto.validoiToimeksianto()) return

      try {
        await this.$store.dispatch('perintaUusi/luoToimeksianto')
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:assignmentSaveFailed'))

        return
      }

      try {
        await this.$store.dispatch('perintaUusi/lahetaLiitteet')
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:assignmentSavedAttachmentFailed'))

        return
      }

      this.$naytaOnnistumisilmoitus(this.tallennusOnnistuiTeksti)
      this.naytaPoistumisvahvistus = false
    },
    async haeVelallisenKenttienValinnat () {
      try {
        this.$store.dispatch('perintaUusi/haeVelallisenKenttienValinnat')
      } catch(e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    palautaAlkutilaan () {
      this.$store.commit('perintaUusi/setLuotuToimeksianto', {})
      this.$store.commit('perintaUusi/setToimeksianto', new Toimeksianto(true))
      this.naytaPoistumisvahvistus = false
    },
    paivitaVelallinen (velallinen) {
      this.toimeksianto.velallinen = velallinen
    },
  },
}

</script>

<style lang="scss" scoped>

.main-div {
  min-height: calc(100vh - 64px);
}

</style>
