<template>
  <div class="perinta-selaa">
    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasToimeksianto
        v-else
        v-model="selectedItem.item"
        @close-button-click="setShowOffCanvas"
        @messageSubmit="refresh"
        @msg-mark-as-unread="refresh"
        @msg-mark-as-read="refresh"
      />
    </v-navigation-drawer>

    <!-- Top bar -->
    <ViewSelaaTopBarBase
      :pikahaun-placeholder-teksti="$t('common:collectionBrowse.quickSearch')"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Tabs -->
    <ViewSelaaTabs
      :vuex-module-name="vuexModuleName"
      styleClass="font-size:12px"
    />

    <!-- Advanced search -->
    <TransitionSlidePanel>
      <div
        v-show="searchPanelOpen"
        class="pa-1 primary darken-1 shadow--inset"
      >
        <InfoValittuValilehti
          :current-tab="currentTab"
        />
        <FormSearchPerinta v-model="searchTerms" />
      </div>
    </TransitionSlidePanel>

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      :item-partitiivi="$t('common:collectionBrowse.assignments')"
      :lataa-csv-btn-config="{ csvData, csvLabels, csvTitle }"
      :uusi-btn-config="{
        toName: 'perinta-uusi',
        itemName: 'toimeksianto',
        permission: onkoLupaLuodaToimeksianto,
      }"
    />

    <!-- Data Table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(true)"
          :data="item"
          :selected-item="selectedItem.item"
          :fade="loading"
          :custom-contextmenu="(true)"
          @click="setSelectedItem({ item })"
          @contextmenu.prevent="avaaMenuContext($event, item)"
        >
          <td class="text-no-wrap">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('open-in-new')"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="$router.push({ name: 'perinta-nayta', params: { id: item.id } })"
                />
              </template>
              <template v-slot:default>
                <span>{{ $t('common:openAssignment') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="max-width">
            <template v-for="taVe in item.toimeksiantovelallinen_set">
              <template v-if="eiOikeuttaNahdaAsiakastaValueVelallinen(item, taVe)">
                <span
                  :key="taVe.id"
                  class="font-italic"
                >{{ $t('common:notAbleToShowRestriction') }}</span>
              </template>
              <template v-else>
                {{ taVe.velallinen.nimi }} ({{ taVe.velallinen.id }})
              </template>
            </template>
          </td>
          <td class="text-no-wrap">
            <template v-if="item.tila">
              {{ item.tila.selite }}
            </template>
          </td>
          <td class="text-no-wrap">
            <template v-if="item.tila && item.vaihe">
              <span
                :class="(item.tila.value == 'XX' || item.tila.value == 'PE' || item.tila.value == 'LO' || item.tila.value == 'MY') ? 'strikethrough' : ''"
              >
                {{ item.vaihe.vaihe }}: {{ item.vaihe.nimi }}
              </span>
            </template>
          </td>
          <td class="text-no-wrap">
            {{ item.paamies.nimi }} ({{ item.paamies.id }})
          </td>
          <td class="text-no-wrap text-right">
            <template v-if="item.saatavarivi_set.length">
              {{ item.saatavarivi_set[0].laskunumero }}
            </template>
          </td>
          <td class="text-no-wrap text-right">
            <template v-if="item.saatavarivi_set.length">
              {{ $date(item.saatavarivi_set[0].laskupaiva) }}
            </template>
          </td>
          <td class="text-no-wrap text-right">
            <template v-if="item.saatavarivi_set.length">
              {{ $date(item.saatavarivi_set[0].erapaiva) }}
            </template>
          </td>
          <td class="text-right">
            {{ item.avoinna | formatSumToFixed2 }}

            <span
              v-if="item.nayta_kuluja_auki_teksti"
              class="text-no-wrap"
            > ({{ $t('common:feesOpen') }})</span>
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <TyhjaTaulukkoOtsikko v-if="tyhjaTaulukko"
      :otsikko="$t('youHaveNoAssignments')"
    >
    </TyhjaTaulukkoOtsikko>
    <ToolbarTyhjaTaulukko v-if="tyhjaTaulukko"
      ikoni="library_add"
      taustavari="success"
      :teksti="$t('newAssignment')"
      :infoteksti="$t('noAssignmentAddInfo')"
      :naytaAina="true"
      :uusi-btn-config="{
        toName: 'perinta-uusi',
        itemName: 'toimeksianto',
        permission: onkoLupaLuodaToimeksianto,
      }"
    >
    </ToolbarTyhjaTaulukko>
    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>

    <!-- Välilehti-dialog -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />
  </div>
</template>

<script>

import {
  FormSearchPerinta,
  InfoValittuValilehti,
  OffCanvasToimeksianto,
  Pagination,
  ToolbarTyhjaTaulukko,
  TyhjaTaulukkoOtsikko,
  TransitionSlidePanel,
  ViewSelaaTabs,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import TrafiLupaMixin from '@/mixins/TrafiLupaMixin'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersToimeksiannot } from '@/utils/tableHeaders'

export default {
  name: 'PerintaSelaa',
  components: {
    FormSearchPerinta,
    InfoValittuValilehti,
    OffCanvasToimeksianto,
    Pagination,
    ToolbarTyhjaTaulukko,
    TyhjaTaulukkoOtsikko,
    TransitionSlidePanel,
    ViewSelaaTabs,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
  },
  mixins: [
    TrafiLupaMixin,
    ViewSelaaMixin,
  ],
  data () {
    return {
      tableHeaders: TableHeadersToimeksiannot,
      menuContextValilehtiRouteName: 'perinta-nayta',
    }
  },
  computed: {
    onkoLupaLuodaToimeksianto () {
      return (
        this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_toimeksianto_uusi') &&
        !(
          this.$store.state.user.vainYksiPaamies &&
          this.$store.state.user.valittuPaamies.esta_toimeksiannon_luonti
        )
      )
    },
    csvLabels () {
      return {
          id: { title:  this.$sovellusIdOtsake },
          velallinen: { title: this.$t('common:debtor') },
          tila: { title: this.$t('common:status') },
          vaihe: { title: this.$t('common:phase') },
          ...(!this.vainYksiPaamies && { paamies: { title: this.$t('common:creditor') } }),
          nro: { title: this.$t('common:invoiceNo') },
          laskupaiva: { title: this.$t('common:invoiceDate') },
          erapaiva: { title: this.$t('common:dueDate') },
          avoinna: { title: this.$t('common:open') },
          lisatieto: { title: this.$t('common:addInfo') },
      }
    },
    csvData () {
      return this.items.map(item => {
        let velallisetStr = ''
        for (const taVe of item.toimeksiantovelallinen_set) {
          if (!this.eiOikeuttaNahdaAsiakastaValueVelallinen(item, taVe)) {
            velallisetStr += (velallisetStr ? ', ' : '') + taVe.velallinen.nimi
          }
        }

        return {
          id: item.id,
          velallinen: velallisetStr,
          tila: item.tila.selite,
          vaihe: item.vaihe ? `${item.vaihe.vaihe}: ${item.vaihe.nimi}` : '',
          ...(!this.vainYksiPaamies && { paamies: `${item.paamies.nimi} (${item.paamies.id})` }),
          nro: item.saatavarivi_set.length ? item.saatavarivi_set[0].laskunumero : '',
          laskupaiva: item.saatavarivi_set.length ? item.saatavarivi_set[0].laskupaiva : '',
          erapaiva: item.saatavarivi_set.length ? item.saatavarivi_set[0].erapaiva : '',
          avoinna: this.$formatSumToFixed2(item.avoinna),
          lisatieto: item.nayta_kuluja_auki_teksti ? this.$t('common:feesOpen') : '',
        }
      })
    },
    csvTitle () {
      return this.$t('common:collectionBrowse.assignmentSearch') + this.$dateFnsFormat(new Date(), 'YYYY-MM-DD-HHmm-ss')
    },
  },
  methods: {
    lisaaViewKohtaisetHaunSuodattimet (query) {
      if (this.searchTerms.asianumeroAlkaen) query.append('id_min', this.searchTerms.asianumeroAlkaen)
      if (this.searchTerms.asianumeroPaattyen) query.append('id_max', this.searchTerms.asianumeroPaattyen)
      if (this.searchTerms.kirjauspaivaAlkaen) query.append('luotu_after', this.searchTerms.kirjauspaivaAlkaen)
      if (this.searchTerms.kirjauspaivaPaattyen) query.append('luotu_before', this.searchTerms.kirjauspaivaPaattyen)
      if (this.searchTerms.laskunErapaivaAlkaen) query.append('saatavarivi__erapaiva_after', this.searchTerms.laskunErapaivaAlkaen)
      if (this.searchTerms.laskunErapaivaPaattyen) query.append('saatavarivi__erapaiva_before', this.searchTerms.laskunErapaivaPaattyen)
      if (this.searchTerms.laskunViite) query.append('saatavarivi__pm_viite', this.searchTerms.laskunViite)
      if (this.searchTerms.laskunumero) query.append('saatavarivi__laskunumero', this.searchTerms.laskunumero)
      if (this.searchTerms.laskupaivaAlkaen) query.append('saatavarivi__laskupaiva_after', this.searchTerms.laskupaivaAlkaen)
      if (this.searchTerms.laskupaivaPaattyen) query.append('saatavarivi__laskupaiva_before', this.searchTerms.laskupaivaPaattyen)
      if (this.searchTerms.paamies) query.append('paamies', this.searchTerms.paamies)
      if (this.searchTerms.perinnanViite) query.append('viite', this.searchTerms.perinnanViite)
      if (this.searchTerms.sopimusnumero) query.append('sopimus', this.searchTerms.sopimusnumero)
      if (this.searchTerms.velallinen) query.append('velallinen', this.searchTerms.velallinen)

      if (typeof this.searchTerms.tila == 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.tila)
      }

      if (typeof this.searchTerms.tyyppi == 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.tyyppi)
      }

      if (typeof this.searchTerms.velallinenTyyppi == 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.velallinenTyyppi)
      }

      return query
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
